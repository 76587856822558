
import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import './user/css/mystyle.css';
import './user/css/style.css';
import './user/css/button.css';
import Protected from './admin/Protected';
import PageLoading from './user/loading_components/PageLoading';




// For  authontication

//axios.defaults.baseURL = "http://localhost:5000/";
//axios.defaults.baseURL = "http://3.215.163.228:5000/";
axios.defaults.baseURL = "https://o4b3nx8227.execute-api.us-east-1.amazonaws.com/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

//----------------------------
function App() {

  const Index = lazy(() => import('./user/Index'));
  const View_Latest_News_Details = lazy(() => import('./user/View_Latest_News_Details'));
  const Aboutus = lazy(() => import('./user/Aboutus'));
  const Information_services = lazy(() => import('./user/Information_services'));
  const Journalist_welfare_society_details = lazy(() => import('./user/Journalist_welfare_society_details'));
  const Accredited_media = lazy(() => import('./user/Accredited_media'));
  const NewsEditorial = lazy(() => import('./user/NewsEditorial'));
  const Media_release = lazy(() => import('./user/Media_release'));
  const ImageReleaseGallary = lazy(() => import('./user/ImageReleaseGallary'));
  const Advertisement = lazy(() => import('./user/Advertisement'));
  const View_latest_news = lazy(() => import('./user/View_latest_news'));
  const Article = lazy(() => import('./user/Article'));
  const Contact_us = lazy(() => import('./user/Contact_us'));
  const Error = lazy(() => import('./user/Error'));

  const Test = lazy(() => import('./user/Test'));




  // Protected route

  const Dashboard = lazy(() => import('./admin/Dashboard'));
  const Banner = lazy(() => import('./admin/Banner'));
  const News = lazy(() => import('./admin/News'));
  const Press = lazy(() => import('./admin/Press'));
  const Latest_news = lazy(() => import('./admin/Latest_news'));
  const Photo_release = lazy(() => import('./admin/Photo_release'));
  const Video_release = lazy(() => import('./admin/Video_release'));
  const Publication = lazy(() => import('./admin/Publication'));
  const Logout = lazy(() => import('./admin/Logout'));


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {/*  ************** Unprotected route**************************  */}

          <Route path="/test" element={
            <Suspense fallback={<PageLoading />}>
              <Test />
            </Suspense>

          } />

          <Route path="/" element={
            <Suspense fallback={<PageLoading />}>
              <Index />
            </Suspense>

          } />

          <Route path="/IPR/view_latest_news_details/" element={
            <Suspense fallback={<PageLoading />}>
              <View_Latest_News_Details />
            </Suspense>

          } />

          <Route path="/IPR/aboutus" element={
            <Suspense fallback={<PageLoading />}>
              <Aboutus />
            </Suspense>

          } />

          <Route path="/IPR/information_services/:id" element={
            <Suspense fallback={<PageLoading />}>
              <Information_services />
            </Suspense>
          }
          />
          <Route path="/IPR/information_services/accredited_media_details" element={
            <Suspense fallback={<PageLoading />}>
              <Accredited_media />
            </Suspense>

          } />
          <Route path="/IPR/information_services/journalist_welfare_society_details/:id" element={
            <Suspense fallback={<PageLoading />}>
              <Journalist_welfare_society_details />
            </Suspense>

          } />

          <Route path="/IPR/bodoland_laithun" element={
            <Suspense fallback={<PageLoading />}>
              <NewsEditorial />
            </Suspense>

          } />
          <Route path="/IPR/media_release/:id" element={
            <Suspense fallback={<PageLoading />}>
              <Media_release />
            </Suspense>

          } />

          <Route path="/IPR/ImageReleaseGallary" element={
            <Suspense fallback={<PageLoading />}>
              <ImageReleaseGallary />
            </Suspense>

          } />


          <Route path="/IPR/advertisement" element={
            <Suspense fallback={<PageLoading />}>
              <Advertisement />
            </Suspense>

          } />
          <Route path="/IPR/view_latest_news" element={
            <Suspense fallback={<PageLoading />}>
              <View_latest_news />
            </Suspense>

          } />

          <Route path="/IPR/article" element={
            <Suspense fallback={<PageLoading />}>
              <Article />
            </Suspense>

          } />

          <Route path="/IPR/contact_us" element={
            <Suspense fallback={<PageLoading />}>
              <Contact_us />
            </Suspense>

          } />
          <Route path="IPR/error" element={
            <Suspense fallback={<PageLoading />}>
              <Error />
            </Suspense>

          } />

          // Protected route
          <Route path="/admin/dashboard" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Dashboard} />
            </Suspense>

          } />
          <Route path="/admin/banner" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Banner} />
            </Suspense>

          } />
          <Route path="/admin/news" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={News} />
            </Suspense>

          } />
          <Route path="/admin/press_adv" element=
            {
              <Suspense fallback={<PageLoading />}>
                <Protected Cmp={Press} />
              </Suspense>

            } />
          <Route path="/admin/latest_news" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Latest_news} />
            </Suspense>

          } />
          <Route path="/admin/photoRelease" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Photo_release} />
            </Suspense>

          } />
          <Route path="/admin/videoRelease" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Video_release} />
            </Suspense>

          } />

          <Route path="/admin/publication" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Publication} />
            </Suspense>

          } />

          <Route path="/admin/logout" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Logout} />
            </Suspense>

          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
