import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { decrypt } from 'n-krypta';
function Protected(props) {
    const Cmp = props.Cmp;
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    useEffect(() => {
        {
            try {
                const redirectError = async () => {
                    localStorage.clear();
                    navigate('/IPR/error');
                }
                const secretKey = "uc-IPR-2023";
                const getToken = decrypt(localStorage.getItem('token').trim(), secretKey);
                const login_email = decrypt(localStorage.getItem('login-email'), secretKey);
                const login_name = decrypt(localStorage.getItem('login-name'), secretKey);

                const decodedHeader = jwtDecode(getToken, { header: true });

                const decodedJWT = jwtDecode(getToken);

                if (decodedHeader.typ === "JWT") {
                    if (decodedJWT.user.application_id === "IPR") {
                        if (decodedJWT.user.email === login_email && decodedJWT.user.name === login_name) {
                            setToken(getToken);
                        } else {
                            //console.log("1");
                            redirectError();
                        }
                    } else {
                        //console.log("2");
                        redirectError();
                    }
                } else {
                    //console.log("3");
                    redirectError();
                }
            } catch (err) {
                //console.log("4");
                localStorage.clear();
                navigate('/IPR/error');
            }
        }
    }, [token])


    return (
        <>
            {(!token == "") ?
                < Cmp token={token} />
                :

                ''}

        </>

    );
}

export default Protected;